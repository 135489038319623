<template>
  <div>
    <v-container>
      <v-row>


        <v-col cols="12" sm="8" md="8">

          <v-chip
              class="ma-2"
              x-large
              color="#455A64"
              style="padding-right: 50px;padding-left: 50px"
          >
            <h1 style="color: white">{{$t('accountingOffice')}}</h1>
          </v-chip>
          <v-timeline :reverse="!$vuetify.breakpoint.smAndDown" :dense="$vuetify.breakpoint.smAndDown">
            <v-timeline-item
                v-for="(d,i) in infoList"
                :key="i"
                dark
                large
            >
              <template v-slot:icon>
                <v-avatar :size="!$vuetify.breakpoint.smAndDown ?168 : 140">
                  <img v-if="d.url" :src="d.url">
                </v-avatar>
              </template>
              <template v-slot:opposite>
                <span style="font-family: 'Khmer OS Muol Light';margin: 50px">{{ $t(d.name) }}</span>
              </template>
              <v-card class="elevation-2" :style="i%2==0 ? ($vuetify.breakpoint.smAndDown ? 'margin-left: 50px;background-color:#952175;' : 'margin-right:50px;background-color:#1F7087;')  : ($vuetify.breakpoint.smAndDown ? 'margin-left: 50px;background-color:#1F7087;' : 'margin-left:50px;background-color:#952175;')">
                <v-card-title class="text-h5">
                  <span style="font-family: 'Khmer OS Muol Light'">{{$t(d.position)}}</span>

                </v-card-title>
                <span v-if="$vuetify.breakpoint.smAndDown" style="font-family: 'Khmer OS Muol Light';margin: 12px">{{ $t(d.name) }}</span>

                <v-divider inset style="color: green"></v-divider>

                <v-card-text style="text-align: left">
                  <p v-if="d.phoneNumber"><b>{{$t('phoneNumber')}} :</b> {{d.phoneNumber}}</p>
                  <p v-if="d.email"><b>{{$t('email')}} :</b> {{d.email}}</p>
                  <p v-if="d.phd"><b>{{$t('phd')}} :</b> {{$t(d.phd)}}</p>
                  <p v-if="d.master"><b>{{$t('master')}} :</b> {{$t(d.master)}}</p>
                  <p v-if="d.bachelor"><b>{{$t('bachelor')}} :</b> {{$t(d.bachelor)}}</p>
                  <p v-if="d.associate"><b>{{$t('associate')}} :</b> {{$t(d.associate)}}</p>

                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-col>

        <v-col cols="12" sm="4" md="4" v-if="!$vuetify.breakpoint.mobile">
          <side-bar-event></side-bar-event>
          <media></media>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SideBarEvent from "@/components/sidebarEvent";
import Media from "@/components/sidebarMedia";

export default {
  name: 'AcademicOffice',
  components: {Media,SideBarEvent},
  data(){
    return{
      infoList:[
        {
          name:"thea_chin",
          position:"chief",
          phoneNumber:"+855-92 226 206",
          bachelor:"accounting",
          master:"",
          phd:"",
          email:"chinthea77@gmail.com",
          url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2Ffianance%2Fphoto_2020-10-24_17-04-23%20-%20Sireyroth%20Yong.jpg?alt=media&token=c5254612-70c6-4e0d-b796-718a70fbe0e2"
        },
        {
          name:"thourn_sophorn",
          position:"deputyChief",
          phoneNumber:"+855-92 605 597",
          bachelor:"agriculture_science",
          associate:"agronomy",
          master:"",
          phd:"",
          email:"thournsophoin281983@gmail.com",
          url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2Ffianance%2F%E1%9E%97%E1%9F%90%E1%9E%8E%E1%9F%92%E1%9E%8C%20-%20sophoin%20thourn.jpg?alt=media&token=0d441402-407a-4a85-8ed2-efa5616ca756"
        },

        {
          name:"mang_dyna",
          position:"technicalTeacher",
          phoneNumber:"+855-15 512 573",
          bachelor:"it",
          master:"",
          phd:"",
          email:"mangdyna@gmail.com",
          url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2Ffianance%2F%E1%9E%98%E1%9F%89%E1%9E%84%E1%9F%8B%20%E1%9E%8C%E1%9E%B8%E1%9E%8E%E1%9E%B6%20-%20KheatrKor%20Gaming-min.JPG?alt=media&token=4db954c3-6f6a-4b88-9e2b-76061d895b4a"
        },

        {
          name:"yong_sireyroth",
          position:"technicalTeacher",
          phoneNumber:"+855-16 552 255",
          bachelor:"marketing",
          master:"",
          phd:"",
          email:"sireyroth.yong@gmail.com",
          url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2Ffianance%2FIMG_1987%20-%20Sireyroth%20Yong.JPG?alt=media&token=95e30e52-d0fc-4905-8799-49c9bf54f35a"
        }


      ]

    }
  }
}
</script>
